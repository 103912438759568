<template>
    <div class="bg-vai-gray-300 d-flex flex-row w-100">
        <div :class="`stage-height bg-vai-gray-100 d-flex flex-row`">
            <div :class="`bg-vai-gray-500 stage-height d-flex flex-column toolbar-min-width`">
                <b-button v-b-toggle.page-control variant="info" block size="sm">
                    Page Controls
                </b-button>
                <b-collapse id="page-control" class="my-2">
                    <div class="d-flex flex-column">
                        <b-button-group v-for="(page, index) in backgroundImageData" :key="index">
                            <b-button  
                                class="w-100"
                                :variant="(currentPage == index) ? 'vai-success-main' : 'vai-secondary-medium'"
                                :disabled="currentPage == index"
                                size="sm"
                                @click="loadPageIndex(index)"
                            >
                                Page {{index + 1}}
                            </b-button>
                            <b-button
                                size="sm"
                                :disabled="index == 0"
                                @click="movePage(index, true)"
                            >
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </b-button>
                            <b-button
                                size="sm"
                                :disabled="index == (backgroundImageData.length - 1)"
                                @click="movePage(index)"
                            >
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </b-button>
                            <b-dropdown
                                variant="info"
                                size="sm"
                            >
                                <template #button-content>
                                    <b-icon-gear-fill></b-icon-gear-fill>
                                </template>
                                <b-dropdown-item @click="setBackground(index)">
                                    <b-icon-file-earmark-arrow-up></b-icon-file-earmark-arrow-up> Set Background
                                </b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item variant="danger" @click="confirmPageDelete(index)" :disabled="dndElements.length == 1">
                                    <b-icon-trash></b-icon-trash> Delete Page
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>
                        <b-button 
                            class="mt-2"
                            variant="vai-warning-darker"
                            size="sm"
                            @click="addPage"
                        >
                            <b-icon-plus-circle-fill></b-icon-plus-circle-fill> Add Page
                        </b-button>
                    </div>
                </b-collapse>
                <b-button v-b-toggle.save-load variant="info" block size="sm">
                    Save & Load
                </b-button>
                <b-collapse id="save-load" class="my-2">
                    <b-tabs content-class="mt-2">
                        <b-tab title="Save" active>
                            <label for="template-name">Template Name</label>
                            <b-form-input id="template-name" size="sm" v-model="currentTemplate.name"></b-form-input>
                            <label for="template-description">Template Description</label>
                            <b-form-textarea id="template-description" rows="3" size="sm" v-model="currentTemplate.description"></b-form-textarea>
                            <b-button v-if="currentTemplate.id == null"
                                block 
                                variant="success"
                                size="sm"
                                @click="saveOrUpdateTemplate(false)"
                            >
                                Save New
                            </b-button>
                            <b-button v-if="currentTemplate.id != null"
                                block 
                                variant="success"
                                size="sm"
                                @click="saveOrUpdateTemplate(true)"
                            >
                                Save Changes
                            </b-button>
                            <b-button v-if="currentTemplate.id != null"
                                block 
                                variant="success"
                                size="sm"
                                @click="saveOrUpdateTemplate(false)"
                            >
                                Save Copy
                            </b-button>
                            <b-button
                                block
                                variant="info"
                                size="sm"
                                @click="manualPreviewRequest"
                            >
                                Preview
                            </b-button>
                        </b-tab>
                        <b-tab title="Load" @click="loadTemplateList">
                            <waiting-dots v-if="loadingTemplateOptions" title="Fetching Templates" ></waiting-dots>
                            <label for="load-template">Load Template</label>
                            <b-form-select v-model="loadTemplateID" :options="availableTemplateOptions"></b-form-select>
                            <div class="mt-3">Selected: <strong>{{ loadTemplateID }}</strong></div>
                            <b-button
                                block
                                variant="vai-warning-darker"
                                size="sm"
                                @click="btnLoadTemplate"
                                :disabled="loadTemplateID == null"
                            >
                                Load Template
                            </b-button>
                        </b-tab>
                        <b-tab title="Preview & Config">
                            <label for="set-test-data">Event ID For Test To Preview</label>
                            <b-form-input v-model="testDataEventID"></b-form-input>
                            <b-button
                                block
                                variant="vai-warning-darker"
                                size="sm"
                                @click="fetchTestData"
                            >
                                Load Test Data
                            </b-button>
                        </b-tab>
                    </b-tabs>
                </b-collapse>
                <div>
                    <!-- The Deletion Zone -->
                    <div v-if="userDraggingItem"
                        ref="delete-zone"
                        :class="`d-flex flex-row align-items-center justify-content-center delete-drop-zone-border ${dropZoneClass}`"
                        @dragover="dropZoneHover"
                        @dragenter.prevent="dropZoneHover"
                        @dragleave="dropZoneLeave"
                    >
                        <div class="d-flex flex-column text-center my-3">
                            <b-icon-trash class="fs-2-5 mx-auto"></b-icon-trash>
                            <span class="fs-1-2-5">Drag Here To Delete</span>
                        </div>
                    </div>
                </div>
                <div 
                    ref="text-dnd"
                    class="bg-vai-gray-200"
                    @mousedown="calculateCurrentDragOffset"
                    @dragend="checkIfOverCanvas"
                    draggable="true"                   
                >
                    Static Text
                </div>
                <div
                    ref="calc-dnd"
                    class="bg-vai-gray-200"
                    @mousedown="calculateCurrentDragOffset"
                    @dragend="calcCheckOverCanvas"
                    draggable="true"
                >
                    Calculated Field
                </div>
                <div v-if="controllerVisible">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-column">
                            <div>
                                <b-icon-type class="fs-1-2-5"></b-icon-type> Text Formatting
                            </div>
                            <div class="d-flex flex-row bg-vai-gray-100">
                                <b-button-group size="sm">
                                    <b-button
                                        @click="toggleBold"
                                        size="sm"
                                        :variant="(curDnDElm.additionalFormatting.find(style => style.attribute == 'font-weight') != undefined) ? 'info' : 'secondary'"
                                    >
                                        <b-icon-type-bold></b-icon-type-bold>
                                    </b-button>
                                    <b-button
                                        @click="decreaseFontSize"
                                        size="sm"
                                    >
                                        <span class="text-nowrap">
                                            <b-icon-type></b-icon-type><b-icon-dash></b-icon-dash>
                                        </span>
                                    </b-button>
                                    <b-button
                                        @click="increaseFontSize"
                                        size="sm"
                                    >
                                        <span class="text-nowrap">
                                            <b-icon-type></b-icon-type><b-icon-plus></b-icon-plus>
                                        </span>
                                    </b-button>
                                </b-button-group>
                                <b-form-input 
                                    type="color"
                                    v-model="curDnDElm.color"
                                ></b-form-input>
                                <b-dropdown id="formatting-options-dd"
                                    size="sm"
                                >
                                    <template #button-content>
                                        <b-icon-gear-fill></b-icon-gear-fill>
                                    </template>
                                    <b-dropdown-item @click="setDefaultStyle">Set This As Default Style</b-dropdown-item>
                                    <b-dropdown-item @click="applyStyleToAll">Apply This Style To All Items</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item @click="applyDefaultStyle">Apply Default Style</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <div>
                            <b-icon-arrows-move class="fs-1-2-5"></b-icon-arrows-move> Positioning
                        </div>
                        <div class="d-flex flex-row justify-content-center bg-vai-gray-100">
                            <div>

                            </div>
                            <b-button
                                @click="moveActiveDNDUpOne"
                            >
                                <b-icon-arrow-up-square></b-icon-arrow-up-square>
                            </b-button>
                            <div>

                            </div>
                        </div>
                        <div class="d-flex flex-row justify-content-center bg-vai-gray-100">
                            <b-button
                                @click="moveActiveDNDLeftOne"
                            >
                                <b-icon-arrow-left-square></b-icon-arrow-left-square>
                            </b-button>
                            <b-button
                                @click="moveActiveDNDDownOne"
                            >
                                <b-icon-arrow-down-square></b-icon-arrow-down-square>
                            </b-button>
                            <b-button
                                @click="moveActiveDNDRightOne"
                            >
                                <b-icon-arrow-right-square></b-icon-arrow-right-square>
                            </b-button>
                        </div>
                        <div v-if="curDnDElm.isEJS || curDnDElm.isImg">
                            <b-icon-bounding-box class="fs-1-2-5"></b-icon-bounding-box> Max Width
                        </div>
                        <div v-if="curDnDElm.isEJS || curDnDElm.isImg" class="d-flex flex-row justify-content-center bg-vai-gray-100">
                            <b-button
                                size="sm"
                                @click="reduceActiveDnDMaxWidth"
                            >
                                <b-icon-dash-circle-fill></b-icon-dash-circle-fill>
                            </b-button>
                            <b-form-input
                                type="number"
                                v-model="curDnDElm.maxWidth"
                                min="0"
                            ></b-form-input>
                            <b-button
                                size="sm"
                                @click="increaseActiveDnDMaxWidth"
                            >
                                <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
                            </b-button>
                        </div>
                        <div v-if="curDnDElm.isImg">
                            <b-icon-bounding-box class="fs-1-2-5"></b-icon-bounding-box> Max Height
                        </div>
                        <div v-if="curDnDElm.isImg" class="d-flex flex-row justify-content-center bg-vai-gray-100">
                            <b-button
                                size="sm"
                                @click="reduceActiveDnDMaxHeight"
                            >
                                <b-icon-dash-circle-fill></b-icon-dash-circle-fill>
                            </b-button>
                            <b-form-input
                                type="number"
                                v-model="curDnDElm.maxHeight"
                                min="0"
                            ></b-form-input>
                            <b-button
                                size="sm"
                                @click="increaseActiveDnDMaxHeight"
                            >
                                <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
                            </b-button>
                        </div>
                        <div v-if="!curDnDElm.isEJS">
                            <b-icon-cursor-text class="fs-1-2-5"></b-icon-cursor-text> Text Content
                        </div>
                        <b-input
                            v-if="!curDnDElm.isEJS"
                            v-model="curDnDElm.value"
                            @focus="disableArrowEvents = true;"
                            @blur="disableArrowEvents = false;"
                            @keypress.enter="clearSelectedDnDElm()"
                        ></b-input>
                    </div>
                </div>
                <div
                    v-for="group in availableDataElements" :key="group.key"
                    class="overflow-mgmt-left-toolbar group-name-min-height"
                >
                    <div
                        class="d-flex flex-row bg-vai-gray-300 position-fixed toolbar-min-width"
                        @click="group.childrenListOpen = !group.childrenListOpen"
                    >
                        <div>
                            <b-icon-chevron-up v-if="group.childrenListOpen"></b-icon-chevron-up>
                            <b-icon-chevron-down v-else></b-icon-chevron-down>
                        </div>
                        <div class="ml-2">
                            {{group.name}}
                        </div>
                    </div>
                    <b-collapse v-model="group.childrenListOpen"
                        class="bg-vai-gray-100 custom-children-margin"
                    >
                        <div
                            class="d-flex flex-row h-100"
                            v-for="child in group.children" :key="child.key"
                        >
                            <div>
                               <b-icon-dash></b-icon-dash> 
                            </div>
                            <div v-if="!child.isImage"
                                class="ml-2"
                                draggable="true"
                                @mousedown="calculateCurrentDragOffset"
                                @dragend="ejsCheckOverCanvas($event, group, child)"
                            >
                                {{child.name}}
                            </div>
                            <div v-else
                                class="ml-2"
                                draggable="true"
                                @mousedown="calculateCurrentDragOffset"
                                @dragend="imageCheckOverCanvas($event, group, child)"
                            >
                                {{child.name}}
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
            <div
                class="flex-row-1 position-relative"
                :style="`width: ${representationWidth};`"
            >
                <b-aspect :aspect="8.5 / 11" 
                    tag="canvas" 
                    id="builder-canvas" 
                    ref="builder-canvas" 
                    :class="`set-max-stage-height position-absolute`" 
                    :style="`width: ${representationWidth}; background-image: url(${backgroundImageData[currentPage]}); background-repeat: no-repeat; background-size: cover;`"
                    :width="repWidthNum" 
                    :height="availableHeight"
                    @click="clearSelectedDnDElm"
                >
                </b-aspect>
                <div
                    v-for="item in dndElements[currentPage]" :key="item.id"
                    :class="`d-flex flex-row align-items-start pt-0 ${(item.id == selectedDnDElmID) ? 'selected-dnd-elm' : 'dnd-elm'}`"
                    :style="`position: absolute; top: ${item.top}px; left: ${item.left}px;`"
                    @dragend="checkIfOverCanvas($event, item.id)"
                    @dragstart="dragStarted($event, item.id)"
                    @mousedown="calculateCurrentDragOffset"
                    draggable="true"
                    
                >
                    <div v-if="!item.isImg"
                        @click="setSelectedDnDElm(item.id)"
                        class="pt-0"
                        :style="`line-height: 1.0; ${(item.maxWidth != undefined && item.maxWidth > 0) ? `width: ${item.maxWidth}px; max-width: ${item.maxWidth}px; word-wrap: break-word;` : ``} ${(item.maxWidth != undefined && item.maxWidth > 0 && item.id == selectedDnDElmID) ? 'background-color: rgba(0.3, 0, 0, 0.25);': ''} color: ${item.color}; ${item.additionalFormatting.map((style)=>{ return style.attribute + ': ' + style.value + ';'; }).join('')}`"
                    >
                        {{(item.isEJS) ? previewEJSVal(item.value) : item.value}}
                    </div>
                    <img v-else
                        @click="setSelectedDnDElm(item.id)"
                        :style="`
                            ${(item.maxWidth != undefined && item.maxWidth > 0) ? `width: ${item.maxWidth}px; max-width: ${item.maxWidth}px;` : ``} 
                            ${(item.maxHeight != undefined && item.maxHeight > 0) ? `height: ${item.maxHeight}px; max-height: ${item.maxHeight}px;` : ``}
                            ${(item.maxWidth != undefined && item.maxWidth > 0 && item.id == selectedDnDElmID) ? 'background-color: rgba(0.3, 0, 0, 0.25);': ''}
                            ${item.additionalFormatting.map((style)=>{ return style.attribute + ': ' + style.value + ';'; }).join('')}`"
                        :src="getEJSImageURL(item.value)"
                    />
                    <div 
                        class="ml-2"
                    >
                        <b-icon-pencil-fill v-if="item.id == selectedDnDElmID && item.isEJS"
                            variant="vai-bright-blue"
                            @click="openCalcEditor()"
                        ></b-icon-pencil-fill>
                        <b-icon-grip-vertical
                            @click="setSelectedDnDElm(item.id)"
                        ></b-icon-grip-vertical>
                    </div>
                </div>
            </div>
        </div>
        <div class="stage-height flex-grow-1 d-flex flex-column">
            <waiting-dots v-if="previewWaiting"
                title="Generating Preview"
            ></waiting-dots>
            <div id="pdf-preview" :class="`stage-height ${(previewWaiting) ? 'flex-shrink-1' : 'flex-grow-1'}`"></div>
        </div>
        
        <b-modal
            id="dnd-calc-editor"
            title="Calculated Field Editor"
            size="xl"
            v-model="showCalcModal"
            @ok="disableArrowEvents = false; cmDoc = null; previewDebounce();"
            @cancel="disableArrowEvents = false; cmDoc = null; previewDebounce();"
            @close="disableArrowEvents = false; cmDoc = null; previewDebounce();"
        >
            <div class="d-flex flex-row">
                <div class="toolbar-min-width">
                    <div
                        v-for="group in availableDataElements" :key="group.key"
                        class="overflow-mgmt-left-toolbar group-name-min-height"
                    >
                        <div
                            class="d-flex flex-row bg-vai-gray-300"
                            @click="group.childrenListOpen = !group.childrenListOpen"
                        >
                            <div>
                                <b-icon-chevron-up v-if="group.childrenListOpen"></b-icon-chevron-up>
                                <b-icon-chevron-down v-else></b-icon-chevron-down>
                            </div>
                            <div class="ml-2">
                                {{group.name}}
                            </div>
                        </div>
                        <b-collapse v-model="group.childrenListOpen"
                            class="bg-vai-gray-100"
                        >
                            <div
                                class="d-flex flex-row"
                                v-for="child in group.children" :key="child.key"
                            >
                                <div>
                                <b-icon-dash></b-icon-dash> 
                                </div>
                                <div 
                                    class="ml-2"
                                    @click="addToCode(group.key, child.key)"
                                >
                                    {{child.name}}
                                </div>
                            </div>
                        </b-collapse>
                    </div>
                </div>
                <div class="d-flex flex-column flex-grow-1">
                    <codemirror v-if="showCalcModal && curDnDElm != undefined && curDnDElm.isEJS"
                        v-model="curDnDElm.value"
                        :options="cmOptions"
                        @ready="onCmReady"
                    ></codemirror>
                    <div 
                        v-if="showCalcModal && curDnDElm != undefined && curDnDElm.isEJS"
                        class="d-flex flex-column"
                    >
                        <div class="fs-1-2-5">
                            Preview Output
                        </div>
                        <div>
                            {{previewEJSVal(curDnDElm.value)}}
                        </div>
                    </div>
                </div>
            </div>
            
        </b-modal>
        <b-modal id="background-uploader"
            v-model="backgroundUploadModal.show"
            title="Upload Background Image"
        >
            <upload-file-drop
                title="Background Image"
                acceptsText="Most Types of Images"
                :acceptsTypes="'image/*'"
                :collapseOnUpload="true"
                :useEmit="false"
                :uploadCallback="backgroundUploaded"
                :includeFileAPIResponse="true"
            ></upload-file-drop>
        </b-modal>
    </div>
</template>

<script>
const butils = require('../../libs/basicUtils.js');
const _ = butils.underscore;
const uuidv4 = butils.uuidv4;
const pdfObject = require('pdfobject');
const ejs = require('ejs');
// require component
import { codemirror } from 'vue-codemirror'
import uploadFile from '@/components/UploadFileDrop.vue';
import WaitingDots from '@/components/subcomponents/WaitingDots.vue'

// require styles
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/theme/monokai.css'
import'codemirror/addon/selection/active-line.js'
import'codemirror/addon/selection/mark-selection.js'
import'codemirror/addon/search/searchcursor.js'
import axios from 'axios';

export default {
    name: 'CustomTestReportBuilder',
    components:{
        codemirror,
        'upload-file-drop': uploadFile,
        'waiting-dots': WaitingDots,
    },
    props:{
        templateID: {
            type: String,
            default: ()=>{
                return null;
            }
        }
    },
    data(){
        return{
            currentPage: 0,
            loading: false,
            canvas: null,
            representationWidth: '100px',
            availableHeight: 0,
            repWidthNum: 0,
            bgImgDataURI: null,
            currentDrag: {
                offsetX: 0,
                offsetY: 0
            },
            dndElements: [],
            backgroundImageFiles: [],
            backgroundImageData: [],
            selectedDnDElmID: null,
            controllerVisible: false,
            placeText: null,
            data: null,
            selectedData: null,
            availableDataElements: [],
            disableArrowEvents: false,
            textSizeParserRegex: /(?<size>[0-9|\.]+)px/,
            showCalcModal: false,
            cmOptions: {
                // codemirror options
                tabSize: 4,
                mode: 'text/javascript',
                theme: "monokai",
                lineNumbers: true,
                line: true,
                styleActiveLine: false,
                styleSelectedText: false,
            },
            userDraggingItem: false,
            currentlyDraggingID: null,
            dropZoneClass: "delete-drop-zone",
            cmDoc: null,
            defaultAdditionalFormatting: [],
            defaultColor: "#000000",
            currentTemplate:{
                name: null,
                description: null,
                id: null
            },
            backgroundUploadModal:{
                show: false,
                index: null
            },
            altFontPtSize: 1,
            previewDebounce: ()=>{},
            previewWaiting: true,
            loadTemplateID: null,
            availableTemplateData: [],
            availableTemplateOptions: [],
            loadingTemplateOptions: false,
            testDataEventID: '3f9e6143-4174-41e6-b7eb-ddb9d7f564a3'
        }
    },
    methods:{
        manualPreviewRequest(){
            this.previewDebounce.cancel();
            this.previewTemplate();
        },
        previewTemplate(){
            this.previewWaiting = true;
            var packed = {
                template: this.packTemplate(),
                data: this.data
            }
            butils.customInstance.responseType.blob().post(`${process.env.VUE_APP_API_BASE_URL}/test_report_templates/preview`, packed)
            .then((response)=>{
                this.previewWaiting = false;
                var fileURL = URL.createObjectURL(response.data);
                pdfObject.embed(fileURL, "#pdf-preview");
            })
            .catch((err)=>{
                this.previewWaiting = false;
                console.log(err)
                butils.apiRequestErrorHandling(err, this, "Preview Failed", false);
            })
        },
        backgroundUploaded(fileID, fileAPIResponse){
            var attachmentFileExt = fileAPIResponse.original.link.split('.').slice(-1).join('.');
            butils.customInstance.responseType.blob().get(`${process.env.VUE_APP_FILE_API_BASE_URL}/${fileID}.${attachmentFileExt}`)
            .then(async (response)=>{
                new Promise((resolve)=>{
                    let reader = new FileReader();
                    reader.onload = () => resolve(reader.result)
                    reader.readAsDataURL(response.data)
                })
                .then((dataURL)=>{
                    this.backgroundImageFiles[this.backgroundUploadModal.index] = `${fileID}.${attachmentFileExt}`
                    this.backgroundImageData[this.backgroundUploadModal.index] = dataURL;
                    this.backgroundUploadModal.show = false;
                    this.previewDebounce();
                })
            })
            .catch((err)=>{
                console.log(err)
                butils.createToast(this, "Failed To Fetch Background Image", "An error occured in the api when attempting to fetch background image.", "warn");
            })
        },
        addPage(){
            this.dndElements.push([]);
            this.backgroundImageFiles.push(null);
            this.backgroundImageData.push(null);
            this.previewDebounce();
        },
        confirmPageDelete(index){
            // Make sure to confirm with the user that they want to delete this page
            this.$bvModal.msgBoxConfirm('This will delete the entire page and all of it\'s content.', {
                title: 'Are You Sure You Want To Delete This Page?',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Yes, Delete Page',
                cancelTitle: 'No',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                
                if(value === true){
                    if(this.currentPage == index){
                        if((this.dndElements.length - 1) == this.currentPage){
                            this.currentPage = this.currentPage - 1;
                        }
                    }
                    this.dndElements.splice(index, 1);
                    this.backgroundImageFiles.splice(index, 1);
                    this.backgroundImageData.splice(index, 1);
                    this.previewDebounce();
                }
            })
            .catch(err => {
                // An error occurred
            })
        },
        loadPageIndex(index){
            this.currentPage = index;
            this.controllerVisible = false;
            this.clearSelectedDnDElm();
        },
        setBackground(index){
            console.log(`Set Background For Page ${index}`)
            this.backgroundUploadModal.index = index;
            this.backgroundUploadModal.show = true;
            // Open Modal With A File Upload Box (Images Only)
            // User then uploads and image, we receive the file id of it
            // We fetch the image from the file api
            // Set the background to that image
        },
        movePage(index, isUp = false){
            var mod = 1;
            if(isUp){
                mod = -1;
            }
            var elmTmp = this.dndElements[index];
            var bgFileTmp = this.backgroundImageFiles[index];
            var bgImgTmp = this.backgroundImageData[index];
            this.dndElements[index] = this.dndElements[index+mod]
            this.backgroundImageFiles[index] = this.backgroundImageFiles[index+mod];
            this.backgroundImageData[index] = this.backgroundImageData[index+mod];
            this.dndElements[index+mod] = elmTmp;
            this.backgroundImageFiles[index+mod] = bgFileTmp;
            this.backgroundImageData[index+mod] = bgImgTmp;
            if(this.currentPage == index){
                this.currentPage = index+mod;
            }else if(this.currentPage == index+mod){
                this.currentPage = index;
            }
            this.previewDebounce();
        },
        packTemplate(update = false){
            var packed = {
                name: this.currentTemplate.name,
                description: this.currentTemplate.description,
                template: []
            };
            if(update){
                packed.id = this.currentTemplate.id
            }

            var canvasBox = this.canvas.$el.getBoundingClientRect();
            this.dndElements.forEach((page, index)=>{
                var pageObj = {
                    background: this.backgroundImageFiles[index],
                    fields: []
                }
                pageObj.fields = page.map((elm)=>{ return JSON.parse(JSON.stringify(elm))})
                pageObj.fields.map((tmp)=>{
                    tmp.left = tmp.left / canvasBox.width;
                    tmp.top = tmp.top / canvasBox.height;
                    if(tmp.maxWidth != undefined){
                        tmp.maxWidth = tmp.maxWidth / canvasBox.width;
                    }
                    if(tmp.maxHeight != undefined){
                        tmp.maxHeight = tmp.maxHeight / canvasBox.height;
                    }
                    if(tmp.additionalFormatting.find(style => style.attribute == 'font-size') != undefined){
                        var fontSize = tmp.additionalFormatting.find(style => style.attribute == 'font-size')
                        var floatVal = parseFloat(fontSize.value.match(this.textSizeParserRegex).groups.size)
                        fontSize.value = Math.round(floatVal/this.altFontPtSize)
                    }
                    return tmp;
                })
                packed.template.push(pageObj)
            }) 
            return packed;
        },
        saveOrUpdateTemplate(update = false){
            if(!update){
                butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/test_report_templates/create`, this.packTemplate(update))
                .then((response)=>{
                    this.currentTemplate.id = response.data.result.id;
                })
                .catch((err)=>{
                    butils.apiRequestErrorHandling(err, this, "Create New Test Report Template", false);
                })
            }else{
                // TODO: Update Existing Template
                butils.instance.post(`${process.env.VUE_APP_API_BASE_URL}/test_report_templates/update`, this.packTemplate(update))
                .then((response)=>{
                    butils.createToast(this, "Update Saved", "", "success", 3)
                })
                .catch((err)=>{
                    butils.apiRequestErrorHandling(err, this, "Update Test Report Template", false);
                })
            }
        },
        setDefaultStyle(){
            this.defaultAdditionalFormatting = _.clone(this.curDnDElm.additionalFormatting);
            this.defaultColor = _.clone(this.curDnDElm.color);
        },
        applyStyleToAll(){
            this.dndElements[this.currentPage].forEach((elm)=>{
                if(elm.id != this.curDnDElm.id){
                    elm.additionalFormatting = _.clone(this.curDnDElm.additionalFormatting);
                    elm.color = _.clone(this.curDnDElm.color);
                }
            })
            this.previewDebounce();
        },
        applyDefaultStyle(){
            this.curDnDElm.additionalFormatting = _.clone(this.defaultAdditionalFormatting);
            this.curDnDElm.color = _.clone(this.defaultColor);
            this.previewDebounce();
        },
        onCmReady(event){
            console.log(event)
            this.cmDoc = event.doc;
            setTimeout(()=>{
                this.cmDoc.cm.refresh();
            }, 100)
            
        },
        //This is the Delete DIV Element method
        deleteElm(id){
            if(this.selectedDnDElmID == id){
                this.clearSelectedDnDElm()
            }
            this.dndElements[this.currentPage] = _.filter(this.dndElements[this.currentPage], (elm)=>{ return elm.id != id; })
            this.previewDebounce();
        },
        dragStarted(event, id){
            this.userDraggingItem = true;
            this.currentlyDraggingID = id;
        },
        dragEnded(event){
            this.userDraggingItem = false;
            this.currentlyDraggingID = null;
        },
        dropZoneHover(event){
            this.dropZoneClass = "delete-drop-zone-hover";
        },
        dropZoneLeave(event){
            this.dropZoneClass = "delete-drop-zone";
        },
        openCalcEditor(){
            this.showCalcModal = true;
            this.disableArrowEvents = true;
        },
        addToCode(gKey, cKey){
            if(this.cmDoc != null){
                this.cmDoc.replaceRange(`data.${gKey}.${cKey}`, this.cmDoc.getCursor())
            }
        },
        onKeyPress(event){
            if(!this.disableArrowEvents){
                switch (event.key ) {
                    case "ArrowUp":
                        this.moveActiveDNDUpOne();
                        break;
                    case "ArrowDown":
                        this.moveActiveDNDDownOne();
                        break;
                    case "ArrowLeft":
                        this.moveActiveDNDLeftOne();
                        break;
                    case "ArrowRight":
                        this.moveActiveDNDRightOne();
                        break;
                    default:
                        break;
                }
            }
            
        },
        setSelectedDnDElm(id){
            if(this.selectedDnDElmID == id){
                this.clearSelectedDnDElm();
            }else{
                this.selectedDnDElmID = id;
                this.controllerVisible = true;
            }
        },
        clearSelectedDnDElm(){
            this.selectedDnDElmID = null;
            this.controllerVisible = false;
        },
        moveActiveDNDUpOne(){
            var match = _.find(this.dndElements[this.currentPage], (elm)=>{
                return elm.id == this.selectedDnDElmID;
            });
            if(match != undefined){
                match.top = match.top - 1;
                this.previewDebounce();
            }
        },
        moveActiveDNDDownOne(){
            var match = _.find(this.dndElements[this.currentPage], (elm)=>{
                return elm.id == this.selectedDnDElmID;
            });
            if(match != undefined){
                match.top = match.top + 1;
                this.previewDebounce();
            }
        },
        moveActiveDNDLeftOne(){
            var match = _.find(this.dndElements[this.currentPage], (elm)=>{
                return elm.id == this.selectedDnDElmID;
            });
            if(match != undefined){
                match.left = match.left - 1;
                this.previewDebounce();
            }
        },
        moveActiveDNDRightOne(){
            var match = _.find(this.dndElements[this.currentPage], (elm)=>{
                return elm.id == this.selectedDnDElmID;
            });
            if(match != undefined){
                match.left = match.left + 1;
                this.previewDebounce();
            }
        },
        setupCanvasListeners(){
            this.canvas = this.$refs["builder-canvas"];
            this.canvasContext = this.canvas.$el.getContext('2d')
            this.altFontPtSize = (this.canvas.$el.getBoundingClientRect().height)/(72 * 11);
        },
        toggleBold(){
            if(this.curDnDElm.additionalFormatting.find(style => style.attribute == 'font-weight') != undefined){
                this.curDnDElm.additionalFormatting = _.filter(this.curDnDElm.additionalFormatting, (style)=>{ return style.attribute != 'font-weight'; })
                this.previewDebounce();
            }else{
                this.curDnDElm.additionalFormatting.push({
                    attribute: 'font-weight',
                    value: 'bold'
                });
                this.previewDebounce();
            }
        },
        decreaseFontSize(){
            this.ensureFontSizeExists();
            var tmp = this.curDnDElm.additionalFormatting.find(style => style.attribute == 'font-size');
            // Parse the existing font size
            var floatVal = parseFloat(tmp.value.match(this.textSizeParserRegex).groups.size)
            if(floatVal > this.altFontPtSize){
                floatVal = floatVal - this.altFontPtSize;
                tmp.value = `${floatVal}px`
                this.previewDebounce();
            }
        },
        increaseFontSize(){
            this.ensureFontSizeExists();
            var tmp = this.curDnDElm.additionalFormatting.find(style => style.attribute == 'font-size');
            // Parse the existing font size
            var floatVal = parseFloat(tmp.value.match(this.textSizeParserRegex).groups.size)
            floatVal = floatVal + this.altFontPtSize;
            tmp.value = `${floatVal}px`
            this.previewDebounce();
        },
        ensureFontSizeExists(){
            if(this.curDnDElm.additionalFormatting.find(style => style.attribute == 'font-size') != undefined){
                return;
            }else{
                this.curDnDElm.additionalFormatting.push({
                    attribute: 'font-size',
                    value: `${12 * this.altFontPtSize}px`
                });
            }
        },
        reduceActiveDnDMaxWidth(){
            if(this.curDnDElm.maxWidth > 0){
                this.curDnDElm.maxWidth--;
                this.previewDebounce();
            }
        },
        increaseActiveDnDMaxWidth(){
            this.curDnDElm.maxWidth++;
            this.previewDebounce();
        },
        reduceActiveDnDMaxHeight(){
            if(this.curDnDElm.maxHeight > 0){
                this.curDnDElm.maxHeight--;
                this.previewDebounce();
            }
        },
        increaseActiveDnDMaxHeight(){
            this.curDnDElm.maxHeight++;
            this.previewDebounce();
        },
        previewEJSVal(ejsText){
            try {
                return ejs.render(`<% ${ejsText} %>`, {data: this.data});
            } catch (error) {
                return "EJS Error"
            }
        },
        getEJSImageURL(ejsText){
            try {
                return `${process.env.VUE_APP_FILE_API_BASE_URL}/${ejs.render(`<% ${ejsText} %>`, {data: this.data})}`
            } catch (error) {
                return "EJS Error"
            }
        },
        checkInDeleteBox(x, y){
            var delZone = this.$refs["delete-zone"];
            if(delZone != undefined && delZone != null){
                console.log(delZone)
                var {top, bottom, left, right} = delZone.getBoundingClientRect()
                var inside = true;
                if(x < left){
                    inside = false;
                }
                if(x > right){
                    inside = false;
                }
                if(y < top){
                    inside = false;
                }
                if(y > bottom){
                    inside = false;
                }
                if(inside){
                    // Its In The Delete Box
                    this.deleteElm(this.currentlyDraggingID);
                }
            }
        },
        calcCheckOverCanvas(event){
            var {top, bottom, left, right} = this.canvas.$el.getBoundingClientRect()
            var elmBotLeftX = event.x - this.currentDrag.offsetX;
            var elmBotLeftY = event.y - this.currentDrag.offsetY;
            var inside = true;
            if(elmBotLeftX < left){
                inside = false;
            }
            if(elmBotLeftX > right){
                inside = false;
            }
            if(elmBotLeftY < top){
                inside = false;
            }
            if(elmBotLeftY > bottom){
                inside = false;
            }

            if(inside){
                // Its In The Canvas
                var obj = {
                    id: uuidv4(),
                    isEJS: true,
                    top: elmBotLeftY - top,
                    left: elmBotLeftX - left,
                    color: this.defaultColor,
                    value: `return "Empty Calc"`,
                    additionalFormatting: _.clone(this.defaultAdditionalFormatting),
                    maxWidth: 0
                };
                this.dndElements[this.currentPage].push(obj);
                this.setSelectedDnDElm(obj.id)
                this.previewDebounce();
            }else{
                // Its not in the canvas
                console.log("Outside Canvas")
            }
            this.dragEnded(event);
        },
        ejsCheckOverCanvas(event, parentGroup, childData){
            var {top, bottom, left, right} = this.canvas.$el.getBoundingClientRect()
            var elmBotLeftX = event.x - this.currentDrag.offsetX;
            var elmBotLeftY = event.y - this.currentDrag.offsetY;
            var inside = true;
            if(elmBotLeftX < left){
                inside = false;
            }
            if(elmBotLeftX > right){
                inside = false;
            }
            if(elmBotLeftY < top){
                inside = false;
            }
            if(elmBotLeftY > bottom){
                inside = false;
            }

            if(inside){
                // Its In The Canvas
                var obj = {
                    id: uuidv4(),
                    isEJS: true,
                    top: elmBotLeftY - top,
                    left: elmBotLeftX - left,
                    color: this.defaultColor,
                    value: `return data.${parentGroup.key}.${childData.key}`,
                    additionalFormatting: _.clone(this.defaultAdditionalFormatting),
                    maxWidth: null
                };
                this.dndElements[this.currentPage].push(obj);
                this.setSelectedDnDElm(obj.id)
                this.previewDebounce();
            }else{
                // Its not in the canvas
                console.log("Outside Canvas")
            }
            this.dragEnded(event);
        },
        imageCheckOverCanvas(event, parentGroup, childData){
            var {top, bottom, left, right} = this.canvas.$el.getBoundingClientRect()
            var elmBotLeftX = event.x - this.currentDrag.offsetX;
            var elmBotLeftY = event.y - this.currentDrag.offsetY;
            var inside = true;
            if(elmBotLeftX < left){
                inside = false;
            }
            if(elmBotLeftX > right){
                inside = false;
            }
            if(elmBotLeftY < top){
                inside = false;
            }
            if(elmBotLeftY > bottom){
                inside = false;
            }

            if(inside){
                // Its In The Canvas
                var obj = {
                    id: uuidv4(),
                    isEJS: false,
                    isImg: true,
                    top: elmBotLeftY - top,
                    left: elmBotLeftX - left,
                    color: this.defaultColor,
                    value: `return data.${parentGroup.key}.${childData.key}`,
                    additionalFormatting: [
                        {
                            attribute: 'object-fit',
                            value: 'contain'
                        }
                    ],
                    maxWidth: 100,
                    maxHeight: 100
                };
                this.dndElements[this.currentPage].push(obj);
                this.setSelectedDnDElm(obj.id)
                this.previewDebounce();
            }else{
                // Its not in the canvas
                console.log("Outside Canvas")
            }
            this.dragEnded(event);
        },
        checkIfOverCanvas(event, dndID = null){
            var {top, bottom, left, right} = this.canvas.$el.getBoundingClientRect()
            var elmBotLeftX = event.x - this.currentDrag.offsetX;
            var elmBotLeftY = event.y - this.currentDrag.offsetY;
            var inside = true;
            if(elmBotLeftX < left){
                inside = false;
            }
            if(elmBotLeftX > right){
                inside = false;
            }
            if(elmBotLeftY < top){
                inside = false;
            }
            if(elmBotLeftY > bottom){
                inside = false;
            }

            if(inside){
                // Its In The Canvas
                if(dndID != null){
                    var match = _.find(this.dndElements[this.currentPage], (elm)=>{
                        return elm.id == dndID;
                    });
                    if(match != undefined){
                        match.top = elmBotLeftY - top;
                        match.left = elmBotLeftX - left;
                    }else{
                        // Its not here, so we will just create one
                        this.createDnDElement(elmBotLeftX - left, elmBotLeftY - top, "Testing")
                    }
                }else{
                    this.createDnDElement(elmBotLeftX - left, elmBotLeftY - top, "Testing")
                }
            }else{
                // Its not in the canvas
                console.log("Outside Canvas")
                this.checkInDeleteBox(elmBotLeftX, elmBotLeftY)
            }
            this.dragEnded(event);
        },
        createDnDElement(x, y, text){
            var obj = {
                id: uuidv4(),
                isEJS: false,
                top: y,
                left: x,
                value: text,
                color: this.defaultColor,
                additionalFormatting: _.clone(this.defaultAdditionalFormatting)
            };
            this.dndElements[this.currentPage].push(obj);
            this.setSelectedDnDElm(obj.id)
            this.previewDebounce();
        },
        calculateCurrentDragOffset(event){
            var {x, y} = event;
            var {top, bottom, left} = event.currentTarget.getBoundingClientRect()
            var offsetX = x - left;
            var offsetY = bottom - y;

            this.currentDrag.offsetX = offsetX;
            this.currentDrag.offsetY = offsetY;

        },
        fetchTestData(){
            this.loading = true;
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/atils/get_bfpa_test_data/${this.testDataEventID}`)
            .then((response)=>{
                this.loading = false;
                this.data = response.data.result;
                var dataGroups = _.keys(this.data)
                var tmpAvail = [];
                dataGroups.forEach((key)=>{
                    let obj = {
                        key: key,
                        name: butils.formatters.capitalizeFirstLetter(key),
                        childrenListOpen: false,
                        children: []
                    };
                    let groupKeys = _.keys(this.data[key]);
                    let tmpChildren = [];
                    groupKeys.forEach((gKey)=>{
                        let gObj = {
                            key: gKey,
                            name: butils.formatters.snakeCaseToHumanReadable(gKey, true),
                            value: this.data[key][gKey],
                            isImage: false,
                        }
                        if(gKey == "technician_signature" || gKey == "photo_of_bfpa" || gKey == "fire_contractor_authorized_signature"){
                            gObj.isImage = true;
                        }
                        tmpChildren.push(gObj);
                    })
                    obj.children = _.sortBy(tmpChildren, (itm)=>{
                        return itm.key;
                    })
                    tmpAvail.push(obj);
                })
                this.availableDataElements = _.sortBy(tmpAvail, (itm)=>{
                    return itm.key;
                })
                this.previewDebounce();
            })
            .catch((err)=>{
                this.loading = false;
                butils.apiRequestErrorHandling(err, this, "Signature Upload")
            })  
        },
        loadTemplateList(){
            this.loadingTemplateOptions = true;
            this.availableTemplateData = null;
            this.availableTemplateOptions = [];
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/test_report_templates/list`)
            .then((response)=>{
                this.loadingTemplateOptions = false;
                this.availableTemplateData = response.data.result
                this.availableTemplateOptions.push(
                    { value: null, text: "-- Select To Load A Template --", disabled: true}
                )
                this.availableTemplateData.forEach((template)=>{
                    var tmp = {
                        value: template.id,
                        text: template.name
                    }
                    this.availableTemplateOptions.push(tmp)
                })
                //Do I put the getTemplates and the options in here? 
                //I am also not sure whta to call the value, is it this.id, text: 'templateName'?
            
            })
            .catch((reject)=>{
                console.log(reject);
            })
        },
        btnLoadTemplate(){
            this.clearTemplateData();
            this.loadTemplate(this.loadTemplateID)
        },
        clearTemplateData(){
            this.dndElements = [];
            this.backgroundImageFiles = [];
            this.backgroundImageData = [];
        },
        setupNewBlankTemplate(){
            this.currentTemplate.id = null;
            this.currentTemplate.name = null;
            this.currentTemplate.description = null;
            this.dndElements = [[]];
            this.backgroundImageFiles = [null];
            this.backgroundImageData = [null];
        },
        loadTemplate(templateID){
            this.currentTemplate.id = templateID;
            var proposedPath = `/home/customtestreport/${this.currentTemplate.id}`;
            if(this.$router.history.current.fullPath != proposedPath){
                this.$router.replace({ path: proposedPath})
            }
            butils.instance.get(`${process.env.VUE_APP_API_BASE_URL}/test_report_templates/${templateID}`)
            .then((response)=>{
                var canvasRect = this.canvas.$el.getBoundingClientRect()
                var report = response.data.result;
                this.currentTemplate.name = report.name;
                this.currentTemplate.description = report.description;
                var backgroundPromises = [];
                report.template.forEach((page)=>{
                    if(page.background == null){
                        backgroundPromises.push(new Promise((resolve, reject)=>{resolve(null)}))
                    }else{
                        backgroundPromises.push(butils.customInstance.responseType.blob().get(`${process.env.VUE_APP_FILE_API_BASE_URL}/${page.background}`))
                    }
                    this.backgroundImageFiles.push(page.background);
                    this.dndElements.push(page.fields.map((fld)=>{
                        let tmp = {
                            ...fld
                        };
                        tmp.left = tmp.left * canvasRect.width;
                        tmp.top = tmp.top * canvasRect.height;
                        if(tmp.maxWidth != undefined){
                            tmp.maxWidth = tmp.maxWidth * canvasRect.width;
                        }
                        if(tmp.maxHeight != undefined){
                            tmp.maxHeight = tmp.maxHeight * canvasRect.height;
                        }
                        if(tmp.additionalFormatting.find(style => style.attribute == 'font-size') != undefined){
                            var fontSize = tmp.additionalFormatting.find(style => style.attribute == 'font-size')
                            fontSize.value = `${fontSize.value * this.altFontPtSize}px`;
                        }
                        return tmp;
                    }));
                })
                Promise.all(backgroundPromises)
                .then((backgrounds)=>{
                    var backgroundTranslationProms = [];
                    backgrounds.forEach((bg)=>{
                        if(bg != null){
                            backgroundTranslationProms.push(new Promise((resolve)=>{
                                let reader = new FileReader();
                                reader.onload = () => resolve(reader.result)
                                reader.readAsDataURL(bg.data)
                            }))
                        }else{
                            backgroundTranslationProms.push(new Promise((resolve, reject)=>{resolve(null)}))
                        }
                    })
                    
                    Promise.all(backgroundTranslationProms)
                    .then((bgDatas)=>{
                        bgDatas.forEach((dataURL)=>{
                            this.backgroundImageData.push(dataURL)
                        })
                        this.manualPreviewRequest()
                    })
                })
            })
            .catch((err)=>{
                butils.apiRequestErrorHandling(err, this, "Get Test Report Template", false);
            })
        }
    },
    watch:{

    },
    computed:{
        curDnDElm: function(){
            return _.find(this.dndElements[this.currentPage], (elm)=>{
                return elm.id == this.selectedDnDElmID;
            });
        },
    },
    beforeCreate(){

    },
    created(){
        // Fetch Loadable Templates
        this.loadTemplateList()
    },
    beforeMount (){
        this.previewDebounce = _.debounce(this.previewTemplate, 3000)
        this.availableHeight = window.innerHeight - 64;
        this.repWidthNum = (8.5 * this.availableHeight) / 11
        this.representationWidth = `${this.repWidthNum}px`
        this.fetchTestData();
    },
    mounted(){
        // Create the keydown listener for arrow keys
        window.addEventListener('keydown', this.onKeyPress)
        this.setupCanvasListeners();
        if(this.templateID != null){
            this.clearTemplateData();
            this.loadTemplate(this.templateID)
        }else{
            this.setupNewBlankTemplate();
        }
    },
    beforeUpdate(){

    },
    updated(){

    },
    beforeDestroy(){

    },
    destroyed(){
        window.removeEventListener('keydown', this.onKeyPress)
    }
}
</script>

<style scoped>
.test-div-hovering{
    top: 0;
    left: 0;
}
.stage-height{
    height: calc(100vh - 64px);
}
.overflow-mgmt-left-toolbar{
    overflow-x: hidden;
    overflow-y: auto;
}
.group-name-min-height{
    min-height: 1.5rem;
}
.custom-children-margin{
    padding-top: 1.5rem;
}
.toolbar-min-width{
    width: 300px;
}

.selected-dnd-elm{
    background-color: rgba(12, 205, 35, 0.35);
}
.dnd-elm{
    background-color: rgba(0, 0, 0, 0.10);
}

.delete-drop-zone-border{
    border-style: dashed;
    border-color: rgb(128, 0, 0);
}
.delete-drop-zone{
    color: #9C0000;
    background-color:rgba(255, 0, 0, 0.05);
}
.delete-drop-zone-hover{
    color: rgb(0, 0, 0);
    background-color:rgba(255, 0, 0, 0.5);
}
</style>